import { makeStyles } from "@mui/styles";
import React from "react";
export default function Info(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 15}
      width={width ? width : 15}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path
          d="M31.2114 15.023C31.1314 11.4323 28.3609 8.5017 25.0302 8.4917C25.0202 8.4917 25.0202 8.4917 25.0102 8.4917C21.6395 8.4917 18.859 11.4323 18.809 15.043C18.759 18.3337 18.759 19.6639 18.809 22.9846C18.859 26.4853 21.6895 29.4359 24.9902 29.4459H25.0002C28.3409 29.4459 31.1314 26.5453 31.2114 22.9746C31.2314 21.8244 31.2314 20.6741 31.2214 19.5239V18.0136C31.2214 17.3935 31.2214 17.0534 31.2314 16.7834C31.2314 16.3233 31.2314 16.0532 31.2114 15.023ZM23.3399 15.8532C23.2799 14.6829 23.5099 13.7628 23.99 13.2727C24.25 13.0026 24.5801 12.8726 24.9802 12.8726H25.0202C26.0304 12.8926 26.7706 13.7928 26.7706 15.013C26.7806 16.0632 26.7806 16.3233 26.7806 16.8034L26.7706 18.0236V19.5039C26.7806 20.6441 26.7806 21.7844 26.7706 22.9246C26.7706 23.6047 26.5305 24.2148 26.1204 24.6149C25.8004 24.935 25.4203 25.085 24.9902 25.085C23.77 25.055 23.3399 23.5747 23.3399 22.3145C23.3399 21.2843 23.3599 20.5341 23.3699 19.864C23.3999 18.6637 23.4199 17.7135 23.3399 15.8532Z"
          fill="#3778C2"
        />
        <path
          d="M29.5211 33.7567C28.941 32.8366 28.1108 32.1264 27.1106 31.7063C26.1104 31.2763 25.0202 31.1562 23.96 31.3763C22.8898 31.5863 21.9296 32.1064 21.1694 32.8766C20.4093 33.6567 19.9092 34.6369 19.7291 35.7071C19.5391 36.7674 19.6791 37.8576 20.1292 38.8478C20.5793 39.838 21.3094 40.6481 22.2396 41.2082C23.0798 41.7183 24.02 41.9784 24.9902 41.9784C25.1002 41.9784 25.2102 41.9684 25.3202 41.9684C26.6505 41.8884 27.9008 41.3183 28.8309 40.3681C29.7611 39.4079 30.3012 38.1476 30.3512 36.8174C30.3913 35.7371 30.1012 34.6769 29.5211 33.7567ZM24.07 36.9774C24 36.7974 23.98 36.5973 24.03 36.3973C24.07 36.2072 24.17 36.0272 24.31 35.8872C24.4501 35.7471 24.6301 35.6571 24.8301 35.6171C24.8902 35.6071 24.9502 35.6071 25.0102 35.6071C25.1402 35.6071 25.2802 35.6271 25.4003 35.6871C25.5903 35.7671 25.7403 35.8972 25.8503 36.0572C25.9604 36.2272 26.0104 36.4173 26.0104 36.6273C26.0104 36.7573 25.9904 36.8874 25.9304 37.0074C25.8804 37.1374 25.8103 37.2374 25.7103 37.3375C25.6203 37.4275 25.5103 37.4975 25.3803 37.5475C25.2602 37.5975 25.1302 37.6275 25.0002 37.6075H24.9902C24.7901 37.6075 24.6001 37.5475 24.4401 37.4375C24.27 37.3175 24.15 37.1674 24.07 36.9774Z"
          fill="#3778C2"
        />
        <path
          d="M42.6737 7.32146C37.9528 2.60052 31.6815 0 25.0002 0C20.0392 0 15.2382 1.45029 11.1074 4.21084C6.98658 6.96139 3.80595 10.8422 1.90557 15.4331C0.00518879 20.014 -0.484909 25.005 0.485285 29.876C1.44548 34.7369 3.81595 39.1678 7.32665 42.6685C10.8374 46.1792 15.2582 48.5497 20.1292 49.5099C21.7495 49.84 23.3799 50 25.0202 50C28.2708 50 31.5115 49.3599 34.5721 48.0896C39.153 46.1892 43.0338 43.0086 45.7843 38.8878C48.5449 34.757 50.0052 29.956 50.0052 24.995C50.0052 18.3137 47.4047 12.0424 42.6737 7.32146ZM44.034 32.8766C42.9938 35.3971 41.4935 37.6475 39.5731 39.5679C37.6527 41.4883 35.4023 42.9886 32.8818 44.0288C30.3713 45.069 27.7207 45.5991 25.0002 45.5991C20.9094 45.5991 16.9486 44.3989 13.5579 42.1284C10.1572 39.858 7.52669 36.6573 5.96638 32.8766C4.39607 29.1058 3.99599 24.985 4.79615 20.9742C5.58631 16.9634 7.5367 13.3127 10.4273 10.4221C13.3279 7.53151 16.9686 5.58112 20.9794 4.79096C22.3197 4.5209 23.6699 4.39088 25.0102 4.39088C27.7007 4.39088 30.3713 4.92098 32.8818 5.96119C36.6625 7.5215 39.8632 10.142 42.1336 13.5427C44.4041 16.9434 45.6043 20.9042 45.6043 24.995C45.6043 27.7155 45.0742 30.3661 44.034 32.8766Z"
          fill="#3778C2"
        />
      </g>
    </svg>
  );
}
