import { makeStyles } from "@mui/styles";
import React from "react";
export default function Sex(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 20}
      width={width ? width : 20}
      viewBox="0 0 60 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M17.2741 19.4146V8.36319L23.1483 14.4265C23.3574 14.6455 23.6162 14.8248 23.895 14.9442C24.4724 15.1931 25.0897 15.1931 25.6871 14.9442C25.9659 14.8248 26.2148 14.6555 26.4338 14.4265C26.8619 13.9785 27.1009 13.3911 27.1009 12.7638C27.1009 12.1465 26.8619 11.5591 26.4338 11.1111L16.8659 0.826353C16.6568 0.59736 16.4179 0.418149 16.1491 0.288719C15.8603 0.159288 15.5716 0.0895947 15.2729 0.0796385C14.9643 0.0696823 14.6756 0.119463 14.3769 0.238938C14.0981 0.358412 13.8492 0.517711 13.66 0.706879C13.5804 0.786528 13.5007 0.866178 13.431 0.945827C13.3613 1.02548 13.2618 1.12504 13.1622 1.2246L3.58435 11.1211C3.15624 11.579 2.91729 12.1665 2.91729 12.7837C2.91729 13.411 3.15624 13.9984 3.58435 14.4464C3.79343 14.6655 4.04234 14.8447 4.34102 14.9741C4.90853 15.2131 5.53577 15.2131 6.12318 14.9641C6.41191 14.8447 6.66082 14.6655 6.8699 14.4564L12.6544 8.47271V19.4444C5.37647 20.6292 -0.0297454 27.0808 0.000123159 34.6774C0.0200355 38.7694 1.59311 42.6125 4.44059 45.5197C7.2781 48.407 11.0216 50 14.9842 50H15.0439C23.4868 49.9602 30.0579 43.1003 30.018 34.3787C29.9782 26.9912 24.4326 20.5297 17.2741 19.4146ZM14.9842 23.8152H15.0141C20.8882 23.8152 25.6871 28.7037 25.717 34.7272C25.7269 37.6543 24.6218 40.4122 22.6106 42.493C21.6051 43.5384 20.4402 44.3449 19.1658 44.8925C17.8914 45.4401 16.5075 45.7288 15.0838 45.7387H15.0439C9.15982 45.7387 4.36094 40.8403 4.34102 34.7969C4.33107 31.82 5.46607 29.0322 7.54692 26.9414C9.54811 24.9303 12.1865 23.8252 14.9842 23.8152Z" />
        <path d="M55.1076 4.52011C52.2303 1.59299 48.437 0.00995619 44.3948 0C40.2928 0.0199124 36.4696 1.66268 33.6421 4.62963C30.8145 7.57666 29.2713 11.5392 29.2912 15.7806C29.3211 23.1979 34.8965 29.7093 42.0849 30.8642V40.7208H39.3967C39.108 40.7208 38.8392 40.7706 38.5803 40.8702C38.3115 40.9797 38.0825 41.129 37.8834 41.3282C37.6843 41.5273 37.525 41.7563 37.4155 42.0151C37.3159 42.274 37.2562 42.5428 37.2562 42.8315C37.2562 43.1004 37.3159 43.3891 37.4155 43.638C37.525 43.9068 37.6843 44.1358 37.8834 44.325C38.0825 44.5241 38.3115 44.6734 38.5803 44.783C38.8392 44.8825 39.108 44.9323 39.3868 44.9323H42.0849V47.6006C42.075 47.9092 42.1347 48.2278 42.2442 48.5165C42.3537 48.8053 42.523 49.0741 42.742 49.2931C42.9511 49.5121 43.21 49.6914 43.5086 49.8208C43.7974 49.9403 44.0961 50 44.4147 50C44.7333 50 45.0319 49.9403 45.3306 49.8208C45.6194 49.6914 45.8782 49.5121 46.0873 49.2931C46.3063 49.0741 46.4756 48.8053 46.5851 48.5165C46.6946 48.2278 46.7544 47.9092 46.7444 47.6205V44.9323H49.4326C49.7213 44.9323 49.9901 44.8825 50.249 44.783C50.5178 44.6734 50.7468 44.5241 50.9459 44.325C51.145 44.1358 51.3043 43.9068 51.4039 43.638C51.5134 43.3891 51.5732 43.1004 51.5732 42.8315C51.5732 42.5428 51.5134 42.274 51.4039 42.0151C51.3043 41.7563 51.145 41.5273 50.9459 41.3282C50.7468 41.139 50.5178 40.9797 50.249 40.8702C49.9901 40.7706 49.7213 40.7109 49.4425 40.7208H46.7444V30.8542C54.112 29.6894 59.5879 23.2079 59.5879 15.5217V15.462C59.568 11.3401 57.975 7.45719 55.1076 4.52011ZM55.2171 15.3524C55.2271 18.3592 54.0722 21.1768 51.9814 23.2776C49.9603 25.3186 47.282 26.4337 44.4545 26.4436C44.4545 26.4436 44.4445 26.4436 44.4346 26.4436C38.5604 26.4436 33.7416 21.595 33.6222 15.6014V15.4221C33.6022 12.4652 34.7173 9.67742 36.7583 7.56671C38.7894 5.46595 41.4875 4.30107 44.3549 4.29112C50.3187 4.25129 55.1873 9.22939 55.2171 15.3524Z" />
      </g>
    </svg>
  );
}
