import { makeStyles } from "@mui/styles";
import React from "react";
export default function City(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 20}
      width={width ? width : 20}
      viewBox="0 0 155 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M96.4403 15.42C102.36 17.28 108.34 19.17 114.34 21.03C118.94 22.46 123.6 23.68 128.13 25.3C134.45 27.56 137.71 32.61 138.83 38.89C139.55 42.93 139.58 47.12 139.59 51.25C139.65 77.33 139.62 103.42 139.62 129.5C139.62 130.23 139.62 130.96 139.62 131.92C142.73 131.92 145.67 131.91 148.62 131.92C152.07 131.93 153.96 133.6 154.02 136.64C154.08 139.73 152.08 141.58 148.61 141.58C104.73 141.59 60.8403 141.59 16.9603 141.59C13.0703 141.59 9.17033 141.6 5.28033 141.59C1.99033 141.58 -0.0296704 139.69 0.000329574 136.7C0.0303296 133.7 2.02033 131.92 5.36033 131.91C8.14033 131.9 10.9203 131.91 13.7003 131.9C13.8203 131.9 13.9403 131.86 14.3003 131.79C14.3303 131.27 14.3903 130.68 14.3903 130.1C14.4003 99.3201 14.4003 68.54 14.3903 37.76C14.3903 31.92 15.4003 26.44 19.7603 22.09C21.7303 20.13 24.0403 18.81 26.7203 18.05C47.3803 12.19 68.0403 6.37004 88.6603 0.370044C93.5403 -1.04996 96.6903 1.77004 96.3303 6.21004C96.1003 9.09004 96.3003 12.02 96.3203 14.92C96.3203 15.04 96.3703 15.16 96.4403 15.42ZM24.0103 131.76C44.9903 131.76 65.7603 131.76 86.5203 131.76C86.5203 91.7 86.5203 51.8 86.5203 11.75C85.6803 11.95 84.9703 12.1 84.2703 12.29C66.2303 17.39 48.2003 22.56 30.1203 27.55C27.0503 28.4 25.2503 30.1 24.4903 33C24.1503 34.3 24.0403 35.69 24.0403 37.04C24.0103 64.54 24.0103 92.04 24.0103 119.54C24.0003 123.6 24.0103 127.66 24.0103 131.76ZM129.96 131.82C130 131.18 130.05 130.64 130.05 130.1C130.05 101.92 130.08 73.73 130 45.55C129.99 43.18 129.39 40.79 128.83 38.46C128.34 36.43 127 35.14 124.88 34.47C118.12 32.33 111.41 30.02 104.67 27.79C101.94 26.88 99.2003 26.01 96.4203 25.1C96.4203 60.8 96.4203 96.3 96.4203 131.82C107.67 131.82 118.77 131.82 129.96 131.82Z" />
        <path d="M43.8605 94.3701H48.5705C49.8605 94.3701 50.9105 95.4201 50.9105 96.7101V101.57C50.9105 102.86 49.8605 103.91 48.5705 103.91H43.8605C42.5705 103.91 41.5205 102.86 41.5205 101.57V96.7101C41.5205 95.4201 42.5705 94.3701 43.8605 94.3701Z" />
        <path d="M66.0002 94.3701H70.6202C71.9102 94.3701 72.9602 95.4201 72.9602 96.7101V101.57C72.9602 102.86 71.9102 103.91 70.6202 103.91H66.0002C64.7102 103.91 63.6602 102.86 63.6602 101.57V96.7101C63.6602 95.4201 64.7002 94.3701 66.0002 94.3701Z" />
        <path d="M43.8703 31.4902H48.5503C49.8403 31.4902 50.8903 32.5402 50.8903 33.8302V38.6302C50.8903 39.9202 49.8403 40.9702 48.5503 40.9702H43.8703C42.5803 40.9702 41.5303 39.9202 41.5303 38.6302V33.8302C41.5303 32.5302 42.5703 31.4902 43.8703 31.4902Z" />
        <path d="M50.9202 75.8605V80.5405C50.9202 81.8305 49.8702 82.8805 48.5802 82.8805H43.8302C42.5402 82.8805 41.4902 81.8305 41.4902 80.5405V75.8605C41.4902 74.5705 42.5402 73.5205 43.8302 73.5205H48.5802C49.8702 73.5205 50.9202 74.5705 50.9202 75.8605Z" />
        <path d="M48.5205 124.85H43.8805C42.5905 124.85 41.5405 123.8 41.5405 122.51V117.74C41.5405 116.45 42.5905 115.4 43.8805 115.4H48.5205C49.8105 115.4 50.8605 116.45 50.8605 117.74V122.51C50.8605 123.8 49.8105 124.85 48.5205 124.85Z" />
        <path d="M70.6602 41.0003H66.0202C64.7302 41.0003 63.6802 39.9503 63.6802 38.6603V33.8703C63.6802 32.5803 64.7302 31.5303 66.0202 31.5303H70.6602C71.9502 31.5303 73.0002 32.5803 73.0002 33.8703V38.6603C73.0002 39.9603 71.9602 41.0003 70.6602 41.0003Z" />
        <path d="M70.6304 82.9103H65.9904C64.7004 82.9103 63.6504 81.8603 63.6504 80.5703V75.8703C63.6504 74.5803 64.7004 73.5303 65.9904 73.5303H70.6404C71.9304 73.5303 72.9804 74.5803 72.9804 75.8703V80.5703C72.9704 81.8703 71.9304 82.9103 70.6304 82.9103Z" />
        <path d="M63.6304 122.43V117.71C63.6304 116.42 64.6804 115.37 65.9704 115.37H70.6704C71.9604 115.37 73.0104 116.42 73.0104 117.71V122.43C73.0104 123.72 71.9604 124.77 70.6704 124.77H65.9704C64.6804 124.77 63.6304 123.72 63.6304 122.43Z" />
        <path d="M43.8302 52.5498H48.5502C49.8402 52.5498 50.8902 53.5998 50.8902 54.8898V59.5698C50.8902 60.8598 49.8402 61.9098 48.5502 61.9098H43.8302C42.5402 61.9098 41.4902 60.8598 41.4902 59.5698V54.8898C41.4902 53.5998 42.5402 52.5498 43.8302 52.5498Z" />
        <path d="M63.6201 59.5596V54.9396C63.6201 53.6496 64.6701 52.5996 65.9601 52.5996H70.6301C71.9201 52.5996 72.9701 53.6496 72.9701 54.9396V59.5596C72.9701 60.8496 71.9201 61.8996 70.6301 61.8996H65.9601C64.6601 61.8996 63.6201 60.8496 63.6201 59.5596Z" />
        <path d="M108.89 57.9302V51.7402C108.89 50.8702 109.6 50.1602 110.47 50.1602H116.71C117.58 50.1602 118.29 50.8702 118.29 51.7402V57.9302C118.29 58.8002 117.58 59.5102 116.71 59.5102H110.47C109.6 59.5102 108.89 58.8002 108.89 57.9302Z" />
        <path d="M108.89 80.77V74.58C108.89 73.71 109.6 73 110.47 73H116.71C117.58 73 118.29 73.71 118.29 74.58V80.77C118.29 81.64 117.58 82.35 116.71 82.35H110.47C109.6 82.35 108.89 81.64 108.89 80.77Z" />
        <path d="M118.35 94.9804V101.16C118.35 102.03 117.64 102.74 116.77 102.74H110.53C109.66 102.74 108.95 102.03 108.95 101.16V94.9804C108.95 94.1104 109.66 93.4004 110.53 93.4004H116.77C117.64 93.4004 118.35 94.1104 118.35 94.9804Z" />
        <path d="M116.68 124.13H110.54C109.67 124.13 108.96 123.42 108.96 122.55V116.24C108.96 115.37 109.67 114.66 110.54 114.66H116.68C117.55 114.66 118.26 115.37 118.26 116.24V122.55C118.25 123.43 117.55 124.13 116.68 124.13Z" />
      </g>
    </svg>
  );
}
