import { makeStyles } from "@mui/styles";
import React from "react";
export default function DrawerIcon(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 20}
      width={width ? width : 20}
      viewBox="0 0 48 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M47.7151 13.5858C47.7151 14.7283 46.9432 15.6443 45.8316 15.809C45.5537 15.8501 45.2655 15.8501 44.9876 15.8501C41.0766 15.8193 33.5529 15.8193 29.5183 15.8193H21.2021C19.6995 15.8193 18.7011 14.9341 18.7011 13.6167C18.7011 12.4743 19.4936 11.5582 20.5949 11.4244C20.8934 11.3833 21.2124 11.3833 21.5006 11.3833H44.9465C45.2244 11.3833 45.4817 11.3833 45.7802 11.4244C46.9226 11.5582 47.7151 12.4434 47.7151 13.5858Z" />
        <path d="M0 2.24372C0 1.10128 0.802799 0.174969 1.90408 0.0411692C2.20255 0 2.52161 0 2.79951 0H44.9465C45.2244 0 45.4817 0 45.7802 0.0411692C46.9226 0.174969 47.7151 1.06011 47.7151 2.20255C47.7151 3.345 46.9432 4.26101 45.8316 4.42569C45.5537 4.46686 45.2655 4.46686 44.9876 4.46686C42.5175 4.44627 31.9988 4.43598 14.5636 4.43598H2.50103C1.00865 4.43598 0 3.55084 0 2.24372Z" />
        <path d="M12.9374 33.7896L4.86826 25.7205C4.47715 25.3191 4.47715 24.6809 4.86826 24.2898L12.9374 16.2104C13.133 16.0148 13.3903 15.9119 13.6476 15.9119C13.7814 15.9119 13.9152 15.9428 14.0387 15.9942C14.4298 16.1486 14.6665 16.5088 14.6665 16.9205V33.0795C14.6665 33.4911 14.4298 33.8514 14.0387 34.0058C13.6579 34.1704 13.2359 34.0881 12.9374 33.7896Z" />
        <path d="M47.7151 24.9691C47.7151 26.1116 46.9432 27.0276 45.8316 27.2026C45.5537 27.2334 45.2655 27.2334 44.9876 27.2334C41.0766 27.2026 33.5529 27.2026 29.5183 27.2026H21.2021C19.6995 27.2026 18.7011 26.3174 18.7011 25C18.7011 23.8678 19.4936 22.9415 20.5949 22.8077C20.8934 22.7666 21.2124 22.7666 21.5006 22.7666H44.9465C45.2244 22.7666 45.4817 22.7666 45.7802 22.8077C46.9226 22.9415 47.7151 23.8267 47.7151 24.9691Z" />
        <path d="M47.7151 36.3524C47.7151 37.4948 46.9432 38.4109 45.8316 38.5858C45.5537 38.6167 45.2655 38.6167 44.9876 38.6167C41.0766 38.5858 33.5529 38.5858 29.5183 38.5858H21.2021C19.6995 38.5858 18.7011 37.7007 18.7011 36.3833C18.7011 35.2511 19.4936 34.3248 20.5949 34.191C20.8934 34.1498 21.2124 34.1498 21.5006 34.1498H44.9465C45.2244 34.1498 45.4817 34.1498 45.7802 34.191C46.9226 34.3248 47.7151 35.2099 47.7151 36.3524Z" />
        <path d="M47.7151 47.7357C47.7151 48.8678 46.9432 49.7839 45.8316 49.9588C45.5537 50 45.2655 50 44.9876 50C42.5278 49.9794 32.0091 49.9691 14.5636 49.9691H2.50103C1.00865 49.9691 0 49.084 0 47.7666C0 46.6241 0.802799 45.7081 1.90408 45.5743C2.20255 45.5331 2.52161 45.5331 2.79951 45.5331H44.9465C45.2244 45.5331 45.4817 45.5331 45.7802 45.5743C46.9226 45.7081 47.7151 46.5932 47.7151 47.7357Z" />
      </g>
    </svg>
  );
}
