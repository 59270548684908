import { makeStyles } from "@mui/styles";
import React from "react";
export default function Search(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 20}
      width={width ? width : 20}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M49.0055 45.953L38.6018 35.5493C37.7583 34.7259 36.9047 33.8723 36.0812 33.0689L35.5992 32.5969C38.9232 28.3892 40.3994 23.6393 39.9876 18.4676C39.5458 12.8339 37.0754 8.14421 32.6669 4.53906C28.9915 1.56658 24.6231 0 20.0238 0C14.6814 0 9.64018 2.1189 5.83419 5.96505C2.29935 9.5702 0.230658 14.28 0.0197725 19.2207C-0.201155 24.1414 1.43572 28.9717 4.60905 32.8379C8.36482 37.3569 14.1491 40.0582 20.084 40.0582C24.5629 40.0582 28.8709 38.5318 32.5765 35.6598C36.7139 39.8072 40.8513 43.9446 44.9987 48.0819L45.9527 49.056C46.6054 49.6887 47.2381 50 47.8808 50C48.1821 50 48.7545 49.9297 49.3168 49.3975C49.8792 48.8552 49.9796 48.2426 49.9796 47.9313C49.9897 47.2585 49.6683 46.6057 49.0055 45.953ZM20.0338 35.7501C15.8462 35.7501 11.9097 34.0932 8.93723 31.1006C5.96474 28.1181 4.33791 24.1916 4.35799 20.0241C4.38812 11.3979 11.4277 4.37839 20.0338 4.37839C24.2315 4.37839 28.178 6.03535 31.1606 9.02792C34.123 12.0104 35.7398 15.9369 35.7197 20.0743C35.6996 24.3924 33.9322 28.3189 31.0903 31.1508C28.2483 33.9928 24.3419 35.7501 20.0338 35.7501Z" />
      </g>
    </svg>
  );
}
