import { makeStyles } from "@mui/styles";
import React from "react";
export default function Notes(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 15,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 30}
      width={width ? width : 30}
      viewBox="0 0 46 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M37.9386 25.6573C39.1183 25.6573 40.068 24.6976 40.068 23.5279L40.098 19.4792C40.098 17.9596 40.098 16.4501 40.068 14.9505C40.038 12.9711 39.3382 11.2716 37.9986 9.89203C34.9895 6.85294 32.5003 4.37369 30.161 2.08437C28.7814 0.73478 27.0519 0.0149955 25.1625 0.0149955C18.2145 -0.0049985 11.1866 -0.0049985 4.26872 0.0149955C3.60892 0.0149955 2.89913 0.194942 2.28931 0.504849C0.769769 1.28461 0 2.63421 0 4.54364V45.8712C0.109967 48.2305 1.90943 50 4.17875 50H35.8892C37.8786 50 39.5481 48.6604 39.938 46.721L40.038 44.3117C40.038 43.1321 39.0883 42.1724 37.9086 42.1724C36.739 42.1724 35.7793 43.1321 35.7793 44.3117V45.4414L35.7693 45.7113H4.82855C4.71858 45.7113 4.47866 45.7113 4.3387 45.7013C4.3187 45.5513 4.3187 45.3014 4.3187 45.1814V4.78357C4.3187 4.6636 4.3187 4.41368 4.3387 4.26372C4.47866 4.25372 4.71858 4.25372 4.82855 4.25372H24.6226L24.6126 5.6733C24.6126 7.69269 24.6026 9.59212 24.6526 11.5015C24.6526 12.2413 24.8525 13.0511 25.2124 13.7209C25.9522 15.1305 27.2818 15.8802 29.0713 15.8802H35.8093V23.5379C35.8093 24.7076 36.769 25.6573 37.9386 25.6573ZM33.47 11.5915H29.1013C29.0113 11.5915 28.9513 11.5915 28.9113 11.5815V6.92292C29.6811 7.71269 30.4409 8.49245 31.1906 9.25222C31.9604 10.042 32.7202 10.8218 33.47 11.5915Z" />
        <path d="M21.2936 13.7909C21.2936 15.0305 20.3639 15.9102 19.0443 15.9102H9.91703C8.87734 15.9102 7.9976 15.2404 7.77767 14.2807C7.54774 13.331 7.97761 12.3413 8.79736 11.9214C9.12727 11.7315 9.59712 11.6215 10.047 11.6215C13.376 11.5915 15.6853 11.5915 19.0143 11.6215C19.7141 11.6215 20.3239 11.8515 20.7438 12.2813C21.1237 12.6612 21.3136 13.201 21.2936 13.7909Z" />
        <path d="M45.4364 28.8863L45.4164 28.9163C45.2764 29.1063 45.1465 29.2762 44.9765 29.4762C43.0271 31.7255 40.6478 34.5047 38.7184 36.754C37.3688 38.3235 36.2391 39.6431 35.6193 40.3529C35.3594 40.6528 35.0595 40.8927 34.7296 41.0827L32.7502 42.0724C32.5802 42.1524 32.4203 42.1824 32.2603 42.1824C31.9904 42.1824 31.7305 42.0824 31.5305 41.9024C31.2006 41.6125 31.0707 41.1727 31.2006 40.7628L31.8404 38.6334C31.9504 38.2435 32.1304 37.8936 32.4403 37.5238C32.8501 37.0639 33.5099 36.2841 34.2597 35.4144C35.4094 34.0748 36.759 32.4953 37.6787 31.4456C38.6884 30.2859 39.2382 29.6361 39.7981 28.9863C40.3579 28.3365 40.9177 27.6767 41.9274 26.5171C42.7572 25.5773 43.9568 25.4974 44.9165 26.3171C45.6863 26.9469 45.9262 28.0766 45.4364 28.8863Z" />
        <path d="M7.72768 21.0387C7.72768 19.939 8.50745 19.0493 9.57713 18.9293C9.83705 18.8793 10.137 18.8793 10.4069 18.8793H29.881C30.141 18.8793 30.3909 18.8793 30.6908 18.9393C31.7505 19.0493 32.5302 19.909 32.5302 21.0187C32.5302 22.1284 31.7705 23.0181 30.6908 23.168C30.4609 23.218 30.1809 23.218 29.911 23.218C26.3221 23.168 23.7529 23.168 20.174 23.168H10.137C8.69739 23.168 7.72768 22.3283 7.72768 21.0387Z" />
        <path d="M30.6908 34.5146C30.4609 34.5646 30.1809 34.5646 29.911 34.5646C26.3321 34.5646 23.7529 34.5646 20.174 34.5246H10.137C8.69739 34.5246 7.72768 33.6649 7.72768 32.3853C7.72768 31.2956 8.50745 30.4059 9.57713 30.2859C9.83705 30.2359 10.137 30.2359 10.4069 30.2359H29.881C30.141 30.2359 30.3909 30.2359 30.6908 30.2859C31.7505 30.4059 32.5302 31.2656 32.5302 32.3653C32.5302 33.4749 31.7705 34.3547 30.6908 34.5146Z" />
        <path d="M7.72768 26.727C7.72768 25.6373 8.48746 24.7576 9.55714 24.5976C9.85705 24.5776 10.147 24.5776 10.4069 24.5776H29.881C30.141 24.5776 30.3709 24.5776 30.6308 24.5976C31.7605 24.7576 32.5302 25.6173 32.5302 26.707C32.5302 27.8167 31.8005 28.6764 30.7108 28.8564C30.4409 28.8863 30.171 28.8863 29.911 28.8863C26.3321 28.8663 23.7529 28.8664 20.174 28.8664H10.137C8.71739 28.8763 7.72768 27.9866 7.72768 26.727Z" />
        <path d="M20.7438 36.9139C21.1237 37.3138 21.3136 37.8336 21.2936 38.4235C21.2936 39.6831 20.3939 40.5428 19.0443 40.5428C18.4845 40.5528 17.9246 40.5528 17.3648 40.5528C16.805 40.5528 16.2351 40.5528 15.6753 40.5428H9.91702C8.87734 40.5428 7.9976 39.873 7.77767 38.9233C7.54774 37.9536 7.96761 36.9839 8.79736 36.564C9.12726 36.3641 9.59712 36.2541 10.047 36.2541C13.376 36.2241 15.6753 36.2241 19.0143 36.2541C19.7141 36.2641 20.3239 36.4841 20.7438 36.9139Z" />
      </g>
    </svg>
  );
}
