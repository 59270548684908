import { makeStyles } from "@mui/styles";
import React from "react";
export default function Email(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 20}
      width={width ? width : 20}
      viewBox="0 0 54 39"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={className.st0}
        d="M53.7993 5.17035C53.7582 5.04039 53.7216 4.90832 53.6849 4.77624C53.6064 4.46167 53.5014 4.15448 53.3711 3.85806C52.9962 2.96523 52.3663 2.2063 51.5623 1.67872C50.7583 1.15115 49.817 0.879031 48.8592 0.897294C46.6737 0.873987 44.4526 0.879275 42.304 0.883866C41.3909 0.885985 40.4779 0.887286 39.5651 0.887757H6.28513C3.33772 0.887757 1.67416 2.17073 0.893463 5.03969C0.88882 5.05109 0.883466 5.06217 0.877427 5.07288L0.816406 5.20108V34.544L0.837677 34.6026C0.899394 34.7746 0.954485 34.9501 1.00958 35.1256C1.12502 35.5451 1.28495 35.9508 1.48657 36.3355C1.90311 37.1149 2.5234 37.7632 3.2795 38.2094C4.03561 38.6556 4.89836 38.8824 5.77327 38.8651C6.89637 38.8764 8.02959 38.8799 9.15548 38.8799C9.95745 38.8799 10.757 38.8799 11.5446 38.8763C12.3441 38.8745 13.1437 38.8733 13.9436 38.8728H48.447C51.2881 38.8728 53.019 37.5107 53.747 34.7001C53.7585 34.6796 53.7697 34.6587 53.7798 34.6382L53.8164 34.5641V5.27841L53.7993 5.17035ZM50.0234 7.5668V32.2072L37.7568 19.8824L50.0234 7.5668ZM16.8718 19.8584L4.59821 32.1779V7.52548L16.8718 19.8584ZM27.3183 24.4581C27.0835 24.437 26.8552 24.3689 26.6467 24.2576C26.4381 24.1464 26.2535 23.9943 26.1035 23.8101L21.6111 19.2429C16.8565 14.409 12.1016 9.57573 7.34652 4.74305H47.37C46.9418 5.17106 46.5133 5.59884 46.0844 6.02638C44.7726 7.33443 43.4644 8.64636 42.1596 9.96218C39.0782 13.0854 36.0002 16.2123 32.9255 19.3428L28.5269 23.8136C28.3778 23.997 28.1942 24.1485 27.9867 24.2593C27.7792 24.37 27.552 24.4377 27.3183 24.4585V24.4581ZM19.6369 22.6458L19.8984 22.9099C20.3478 23.3648 20.8088 23.8306 21.2683 24.2974L22.161 25.2075C22.6317 25.688 23.1033 26.1677 23.5759 26.6466C24.0604 27.1563 24.6415 27.5618 25.2844 27.8388C25.9272 28.1157 26.6187 28.2585 27.3173 28.2585C28.0159 28.2585 28.7073 28.1157 29.3502 27.8388C29.993 27.5618 30.5741 27.1563 31.0586 26.6466C31.8908 25.8075 32.7202 24.9651 33.5468 24.1194L34.8823 22.7595C34.9192 22.722 34.9569 22.6846 34.9939 22.649L47.3023 35.0115H7.33257L19.6369 22.6458Z"
      />
    </svg>
  );
}
