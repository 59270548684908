import { makeStyles } from "@mui/styles";
import React from "react";
export default function ChangedPassword(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <>
      <svg
        height={height ? height : 24}
        width={width ? width : 24}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className={className.st0}>
          <path d="M33.6466 21.05H32.0195C32.0096 20.7523 31.9997 20.4745 31.9898 20.1868C31.9699 19.5816 31.9501 19.0161 31.8707 18.4407C31.3945 15.0675 28.3388 12.4185 24.906 12.4185C21.4138 12.3987 18.4077 14.9484 17.9017 18.3712C17.8124 18.9665 17.7925 19.5519 17.7826 20.167C17.7727 20.4547 17.7628 20.7424 17.7429 21.05H16.0563C14.7765 21.0797 14.0522 21.8337 14.0522 23.0937V35.3563C14.0522 36.7056 14.7864 37.4398 16.1258 37.4398H33.6466C34.9959 37.4398 35.7102 36.7255 35.7102 35.3563V23.1334C35.7102 21.7643 34.9959 21.05 33.6466 21.05ZM21.8503 21.1095C21.8602 20.9706 21.8602 20.8218 21.8602 20.6729C21.8702 20.2563 21.8801 19.8296 21.9396 19.4229C22.1876 17.8553 23.4873 16.7838 25.0152 16.8831C26.6125 16.9723 27.7931 18.1728 27.8923 19.7999C27.9122 20.1273 27.9022 20.4448 27.8923 20.7821V21.1095H21.8503ZM31.9005 24.7109V33.4415H17.8025V24.7109H31.9005Z" />
          <path d="M49.6793 24.9093C49.719 25.4946 49.5205 26.0502 49.1039 26.4967C48.6772 26.9332 48.1613 27.1614 47.6157 27.1614C46.9906 27.1614 46.4549 26.9431 46.058 26.5364C45.6414 26.1296 45.4032 25.5442 45.4032 24.949C45.314 19.3435 43.3496 14.492 39.5795 10.5434C36.1567 6.94199 32.0196 4.87838 27.2772 4.422C19.2609 3.67791 12.8121 6.67412 8.07969 13.3213C8.71465 13.2023 9.33969 13.1031 9.94488 13.0138C11.066 12.8352 12.1573 13.5694 12.3756 14.6607C12.5145 15.2361 12.4053 15.8909 12.0779 16.3671C11.8001 16.8235 11.3537 17.1311 10.808 17.2402C9.77622 17.4386 8.71465 17.6172 7.68285 17.7958L5.65892 18.1033C5.29184 18.1629 4.84538 18.2323 4.39893 18.272C3.28775 18.3514 2.44445 17.8057 2.09721 16.7838C1.76981 15.8711 1.46225 14.998 1.16462 14.1547C0.807454 13.1328 0.470133 12.1705 0.122891 11.2279C-0.273957 10.0771 0.321315 8.85678 1.46225 8.45993C2.58335 8.06308 3.75405 8.62859 4.18066 9.76953L4.55767 10.8311C4.59735 10.7716 4.64696 10.7021 4.69656 10.6426L5.05373 10.1664C5.45058 9.63063 5.87719 9.07505 6.3038 8.55914C10.1532 4.02515 15.0047 1.25714 20.7292 0.324547C30.9381 -1.34221 41.2661 3.51917 46.4053 12.4185C48.082 15.2956 49.1138 18.5002 49.4809 21.9528C49.59 22.9052 49.6495 23.8676 49.6793 24.9093Z" />
          <path d="M49.719 39.4141C49.719 39.8605 49.5999 40.3169 49.3618 40.7733C48.9055 41.3586 48.1812 41.686 47.4669 41.6364C46.7625 41.6067 46.2168 41.2594 45.8894 40.6443C45.6811 40.1879 45.4826 39.7216 45.3239 39.295C45.2941 39.2454 45.2644 39.176 45.2445 39.0966L45.2346 39.0867C44.9072 39.5827 44.5997 39.9994 44.2822 40.4062C40.403 45.4462 35.4027 48.5416 29.4004 49.6032C27.9023 49.871 26.4637 50 24.9954 50C14.9452 50 5.67879 43.8687 1.93849 34.7412C0.688424 31.7252 0.0534668 28.4512 0.0534668 25.0184C0.0534668 23.7683 0.956296 22.8358 2.15676 22.8358C3.36715 22.8358 4.28982 23.7584 4.3295 24.9787C4.41879 30.6041 6.36335 35.4456 10.0937 39.3546C13.5661 42.9758 17.733 45.0493 22.4853 45.5057C30.5215 46.2498 36.9802 43.2536 41.6729 36.6064C41.0379 36.6957 40.4328 36.785 39.8176 36.8743C38.6271 37.0528 37.5754 36.3385 37.3572 35.1976C37.2481 34.5626 37.3671 33.9673 37.6846 33.4911C37.9624 33.0347 38.4287 32.7272 39.0339 32.6181C40.145 32.3899 41.286 32.2113 42.3872 32.0327L42.8833 31.9533C43.1412 31.9037 43.419 31.864 43.6869 31.8244C43.9548 31.7847 44.2227 31.745 44.4905 31.6954C44.5203 31.6855 44.5798 31.6755 44.6393 31.6557C44.679 31.6557 44.7187 31.6458 44.7584 31.6359L44.8179 31.6259C46.2962 31.3184 47.2387 31.864 47.7645 33.3423L48.2606 34.771C48.7765 36.2195 49.2229 37.4993 49.6099 38.67C49.6793 38.918 49.719 39.166 49.719 39.4141Z" />
        </g>
      </svg>
    </>
  );
}
