import { makeStyles } from "@mui/styles";
import React from "react";
export default function Delete(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 22}
      width={width ? width : 22}
      viewBox="0 0 116 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M114.18 26.92C111.17 20.35 105.64 16.81 98.1901 16.67C96.2801 16.64 94.2601 16.62 91.8201 16.62C89.9701 16.62 88.1101 16.63 86.2601 16.64C84.4901 16.65 82.73 16.66 80.97 16.66H77.99C75.54 5.22 66.0701 0 58.0301 0C57.7201 0 57.42 0 57.11 0.02C47.45 0.47 39.88 7.1 37.91 16.65H31.6301C29.3401 16.64 27.0501 16.64 24.7601 16.64C22.3901 16.64 20.03 16.64 17.67 16.66C9.32003 16.7 2.45006 22.03 0.570058 29.92C-1.42994 38.33 1.93005 46.25 9.12005 50.11C10.02 50.59 10.16 50.84 10.16 51.92C10.13 65.51 10.13 79.33 10.12 92.7C10.12 103.34 10.12 113.99 10.11 124.64C10.1 129.44 11.92 133.92 15.24 137.24C18.57 140.57 23.01 142.41 27.73 142.41H27.8C34.7 142.38 42.2801 142.37 51.6501 142.37H65.8901C70.6401 142.38 75.3801 142.38 80.1301 142.38H88.16C97.92 142.37 105.64 134.69 105.74 124.9C105.76 122.63 105.76 120.32 105.75 118.09C105.75 116.98 105.74 115.88 105.74 114.78V104.83C105.75 86.83 105.75 68.83 105.73 50.82V50.67C105.77 50.65 105.82 50.62 105.89 50.59C115.68 45.81 117.79 34.8 114.18 26.92ZM57.54 10.53H57.86C61.83 10.53 65.5701 13.13 66.9501 16.6H48.87C49.91 14.02 52.96 10.69 57.54 10.53ZM95.16 64.41C95.16 84.13 95.1601 103.85 95.1401 123.57C95.1401 124.91 95.0101 126.14 94.7601 127.22C94.1601 129.76 91.2 131.74 89.04 131.76C83.37 131.81 77.39 131.83 70.73 131.84C64.91 131.84 59.0901 131.85 53.2701 131.85L40.35 131.84H30.72L29.9001 131.85H29.1C28.46 131.85 27.9801 131.84 27.5301 131.82C23.4201 131.61 20.75 128.84 20.73 124.75C20.7 118.4 20.7001 111.93 20.7001 105.68V52.41H95.16V64.41ZM102.77 40.17C101.49 41.27 99.9101 41.8 97.9501 41.8H58.92C45.22 41.8 31.51 41.8 17.8 41.79C14.44 41.79 11.9001 40.04 11.0101 37.11C9.96006 33.64 10.86 30.45 13.41 28.56C14.65 27.65 16.1 27.22 17.97 27.22C20.78 27.23 23.5501 27.23 26.3301 27.23H98.0301C101.3 27.24 103.7 28.81 104.77 31.67C105.93 34.78 105.15 38.11 102.77 40.17Z" />
        <path d="M83.4701 94.7199L83.4801 105.18V115.63C83.4801 118.04 82.3201 119.86 80.2101 120.76C79.5101 121.05 78.8001 121.2 78.1001 121.2C76.8301 121.2 75.6001 120.7 74.5501 119.75C73.0601 118.4 72.8701 116.64 72.8701 115.25C72.8801 111.93 72.8801 108.62 72.8801 105.3V93.5199C72.8801 86.9399 72.8801 80.3599 72.8901 73.7799C72.8901 70.4499 75.0801 68.1299 78.2001 68.1299C78.3101 68.1299 78.4301 68.1299 78.5501 68.1399C81.4401 68.2899 83.4601 70.6299 83.4701 73.8099C83.4801 77.5399 83.4801 81.2599 83.4801 84.9799L83.4701 89.4399V94.7199Z" />
        <path d="M63.2401 73.8896C63.2301 80.1196 63.2301 86.3496 63.2301 92.5796V103.92C63.2301 107.81 63.2401 111.7 63.2301 115.59C63.2201 118.82 60.9901 121.16 57.8901 121.16C54.8001 121.14 52.6401 118.82 52.6401 115.52C52.6301 110.66 52.6401 105.8 52.6401 100.94V96.1096H52.6301V88.5696C52.6201 83.6496 52.6201 78.7296 52.6401 73.8096C52.6401 70.5896 54.9401 68.0596 57.8601 68.0596C58.7301 68.0596 59.5801 68.2796 60.3901 68.7196C61.6901 69.4096 63.2401 70.8696 63.2401 73.8896Z" />
        <path d="M42.9499 114.92C42.9499 116.35 42.5199 117.9 41.8199 119.05C40.9999 120.4 39.4799 121.2 37.7299 121.2C37.1199 121.2 36.5099 121.1 35.9299 120.9C33.7599 120.15 32.3999 118.18 32.3899 115.75C32.3799 112.3 32.3799 108.86 32.3899 105.41V94.6101L32.3799 88.9801C32.3799 83.9301 32.3799 78.8701 32.3899 73.8201C32.3899 70.4801 34.5799 68.1301 37.7099 68.1201H37.7299C40.0999 68.1201 42.1599 69.7001 42.7299 71.9701C42.8899 72.6001 42.9698 73.3001 42.9698 74.1101L42.9799 78.0101C42.9899 90.1101 42.9999 102.61 42.9499 114.92Z" />
      </g>
    </svg>
  );
}
