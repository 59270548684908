import { makeStyles } from "@mui/styles";
import React from "react";
export default function Password(props) {
  const { height, width, fill, stroke } = props;
  const styles = makeStyles({
    st0: {
      fill: fill ? fill : "none",
      stroke: stroke ? stroke : "none",
      strokeWidth: 20,
      strokeMiterlimit: 10,
    },
  });
  const className = styles();
  return (
    <svg
      height={height ? height : 26}
      width={width ? width : 26}
      viewBox="0 0 155 142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={className.st0}>
        <path d="M19.8716 39.7503C19.2316 27.5203 22.7016 16.9103 32.2116 8.84028C39.2316 2.89028 47.4316 0.190279 56.6216 0.570279C72.4516 1.23028 91.2816 14.9303 89.7716 39.7203C91.2416 40.0903 92.7816 40.3703 94.2616 40.8603C103.382 43.9303 109.272 51.7103 109.382 61.3803C109.532 75.1603 109.552 88.9403 109.382 102.72C109.232 115.15 99.6516 124.38 87.1816 124.4C65.5216 124.43 43.8716 124.44 22.2116 124.4C9.70161 124.38 0.111596 114.86 0.0415961 102.35C-0.0384039 89.2303 0.0216071 76.1003 0.0216071 62.9803C0.0316071 50.9703 6.61161 42.7403 18.3016 40.1103C18.7416 40.0203 19.1816 39.9103 19.8716 39.7503ZM54.8116 50.3103C44.2216 50.3103 33.6416 50.2903 23.0516 50.3203C15.1216 50.3403 10.6716 54.7903 10.6716 62.6703C10.6616 75.6003 10.6616 88.5303 10.6716 101.46C10.6816 109.38 15.0316 113.93 22.9416 113.96C44.1116 114.03 65.2916 114.03 86.4716 113.96C94.4416 113.94 98.9716 109.24 98.9816 101.29C98.9916 88.5503 98.9916 75.8003 98.9816 63.0603C98.9716 54.9003 94.4216 50.3203 86.3016 50.3103C75.7916 50.3003 65.3016 50.3103 54.8116 50.3103ZM30.5516 39.7103C46.7516 39.7103 62.7916 39.7103 78.8116 39.7103C80.5516 30.4903 76.2616 20.5403 68.3416 15.2203C60.1116 9.69028 49.3416 9.58028 41.1416 15.2503C32.6516 21.1203 29.5316 29.4603 30.5516 39.7103Z" />
        <path d="M49.4918 89.31C49.6418 86.14 49.4918 83.39 46.9618 80.65C43.5818 77 44.7718 71.12 48.6818 67.91C52.5318 64.75 58.2218 65.1 61.6718 68.72C65.0918 72.31 65.3018 78.11 61.8218 81.68C60.4118 83.13 59.9818 84.52 60.0618 86.38C60.1618 88.72 60.1618 91.06 60.0618 93.4C59.9218 96.79 57.6719 99.06 54.6619 99.02C51.6019 98.98 49.6018 96.75 49.5018 93.24C49.4618 91.93 49.4918 90.62 49.4918 89.31Z" />
      </g>
    </svg>
  );
}
